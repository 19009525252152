/* common */
.hidden {
    display: none !important;
}

.link {
    text-decoration: none;
    color: inherit;
}

.pointer {
    cursor: pointer;
}

/* dimension */
.full-width {
    width: 100% !important;
}

.full-height {
    height: 100%;
}

.view-height-100 {
    height: 100vh;
}

/* flex */
.justify-content-center {
    justify-content: center !important;
}

.flex-grow-1 {
    flex-grow: 1
}

/* drawer */
.drawer {
    width: 240px;
    flex-shrink: 0;
}

.drawer-paper {
    width: 240px;
}

/* background */
.blackboard-background {
    background-color: #116D4D !important;
}

.blackboard-dark-background {
    background-color: #127b57 !important;
}

.white-backgroud {
    background-color: white !important;
}

.blue-background {
    background-color: #1c84c6 !important;
}

.red-background {
    background-color: #ed5565 !important;
}

.yellow-background {
    background-color: #f8ac59 !important;
}

.green-background {
    background-color: #1ab394 !important;
}

/* color */
.white-text {
    color: white !important;
}

.blue-text {
    color: #1c84c6 !important;
}

.red-text {
    color: #ed5565 !important;
}

.yellow-text {
    color: #f8ac59 !important;
}

.green-text {
    color: #1ab394 !important;
}

.grey-text {
    color: #252525 !important;
}

/* spacing */
.margin-right-5 {
    margin-right: 5px;
}

.margin-right-10 {
    margin-right: 10px;
}

/* overflow */
.overflow-y-scroll {
    overflow-y: scroll;
}

/* card */
.card {
    border-radius: 5px;
}

.card-header {
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    /* background-color: #5765af; */
    color: #FFFFFF;
    font-weight: 600;
    font-size: 20px;
}

.card-content {
    height: 110px
}

/* root */
.root {
    display: flex;
    color: #252525 !important;
}

.root-content {
    flex-grow: 1;
    padding: 24px;
}

/* menu */
.menu-title {
    flex-grow: 1;
}

.menu-drawer {
    width: 240px;
    flex-shrink: 0;
}

.menu-paper {
    width: 240;
}

/* tab */
.MuiTab-textColorPrimary.Mui-selected {
    color: #1c84c6 !important;
}

/* expand */
.expand-header {
    background-color: #f7f7f7 !important;
}

.expand-padding {
    padding: 15px !important;
}

.expand-button-icon {
    font-size: 20px;
    margin-right: 3;
}

/* table */
.table-header {
    font-weight: bold !important;
}

.table-sort-label {
    height: 10px;
}

/* dialog */
.dialog-app-bar {
    position: relative !important;
}

.dialog-title {
    margin-left: 24px;
    flex: 1;
}

/* check box */
.uncheck-box {
    background-color: #d9d9d95c;
}

.check-box {
    background-color: #f8ac59;
    color: #FFFFFF;
}

/* dropzone */
.dropzone {
    min-height: unset !important;
    padding-top: 20px;
    padding-bottom: 20px;
}

.dropzone .MuiSvgIcon-root {
    margin-bottom: 10px;
}

/* customise */
.class-box {
    font-size: 20px;
    border-radius: 5px;
}

.datetime-box-text {
    font-size: 25px !important;
}

.datatime-box-icon {
    font-size: 55px !important;
}

.blackboard-tab {
    font-size: 20px !important;
}

.homework-cell {
    padding: 10px !important;
    border: 1px solid #FFFFFF;
    font-size: 25px !important;
}

.homework-subject-cell {
    font-weight: bold !important;
    width: 15%;
}

.homework-box {
    background-color: #d9d9d95c;
    border-radius: 5px;
}

.collect-box-text {
    font-size: 25px;
}

.collect-box-chip {
    margin-right: 8px;
    font-size: 15px;
}

@media (min-width: 960px) {
    .class-box {
        font-size: 40px;
    }

    .datetime-box-text {
        font-size: 60px !important;
    }

    .datatime-box-icon {
        font-size: 110px !important;
    }

    .blackboard-tab {
        font-size: 40px !important;
    }

    .homework-cell {
        font-size: 50px !important;
    }

    .collect-box-text {
        font-size: 50px;
    }

    .collect-box-chip {
        font-size: 30px;
    }
}